import { DrugInteractionCountResult } from "domain/entities/live-search";
import { LiveSearchRepository } from "domain/repositories";

export default class DrugInteractionCountUseCase {
    private liveSearchRepository: LiveSearchRepository;
    
    public constructor(liveSearchRepository: LiveSearchRepository) {
        this.liveSearchRepository = liveSearchRepository
    }

    public async drugInteractionCount(): Promise<([DrugInteractionCountResult[], null]) | ([null, Error])> {
        
            const [result, error] = await this.liveSearchRepository.drugInteractionCount();
            if (result !== null && error === null) {
                return [result, null];
            } 
            
            if (error !== null) {
                return [null, error];
            }

            return [null, new Error("Error desconocido")];
    }
}