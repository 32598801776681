import { DrugTripletInteraction } from "domain/entities/drug-triplet-interaction";
import { Result } from "domain/entities/result";
import { DrugTripletInteractionRepository } from "domain/repositories";

export default class UpdateUseCase {
    private drugTripletInteractionRepository: DrugTripletInteractionRepository;
    
    public constructor(drugTripletInteractionRepository: DrugTripletInteractionRepository) {
        this.drugTripletInteractionRepository = drugTripletInteractionRepository
    }

    public async updateDrugTripletInteraction(id: number, drugTripletInteraction: DrugTripletInteraction, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugTripletInteractionRepository.updateDrugTripletInteraction(id, drugTripletInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}