import { Disease, RequestBatchResult } from "domain/entities/disease";
import { DiseaseRepository } from "domain/repositories";

export default class CreateBatchUseCase {
    private diseaseRepository: DiseaseRepository;
    
    public constructor(diseaseRepository: DiseaseRepository) {
        this.diseaseRepository = diseaseRepository
    }

    public async createDiseaseBatch(disease: Disease[], token: Promise<[string | null, string | null]>): Promise<[RequestBatchResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.diseaseRepository.createDiseaseBatch(disease, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}