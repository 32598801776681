import { DrugResult, Drug, RequestResult, RequestBatchResult } from "domain/entities/drug";
import { Result } from "domain/entities/result";
import { DrugRepository } from "domain/repositories";
import Base from "data/base";

export class DrugApi implements DrugRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/drugs";
    }

    /**
     * Obtiene todas las drogas
     * @returns retorna un array con las drogas
     */
    async getAllDrugs(): Promise<[DrugResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una droga por su ID
     * @param id 
     * @returns retorna una droga
     */
    async getDrug(id: number): Promise<[null, Error] | [DrugResult, null]> {
        try {
            const url: string = await DrugApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una droga
     * @param drug 
     * @param token 
     * @returns Retorna el resultado de la creación  
     */
    async createDrug(drug: Drug, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dru_name: drug.name,
                dru_description: drug.description,
                dru_tech_description: drug.techDescription,
                dcl_id: drug.classId,
                dru_cas: drug.cas,
                dru_side_effects: drug.sideEffects,
                dru_sources: drug.sources
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea drogas en lote
     * @param drugs 
     * @param token 
     * @returns Retorna el resultado de la creación  
     */
    async createDrugBatch(drugs: Drug[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: drugs.map(d => ({
                    dru_name: d.name,
                    dru_description: d.description,
                    dru_tech_description: d.techDescription,
                    dcl_id: d.classId,
                    dru_cas: d.cas,
                    dru_side_effects: d.sideEffects,
                    dru_sources: d.sources
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una droga
     * @param drug 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrug(id: number, drug: Drug, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dru_name: drug.name,
                dru_description: drug.description,
                dru_tech_description: drug.techDescription,
                dcl_id: drug.classId,
                dru_cas: drug.cas,
                dru_side_effects: drug.sideEffects,
                dru_sources: drug.sources
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una droga
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrug(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}