import { useCallback, useMemo, useState } from "react";

import { LiveSearchTApi } from "data";
import { InteractionSearchTResult } from "domain/entities/live-searcht";
import InteractionSearchTUseCase from "domain/use-cases/live-searcht/InteractionSearchUseCase";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";

// Obtiene de la API las tripletas para cierto IDs de drogas.
const _getInteractions = (drugIds: number[]) => {
    const liveSearchTRepository = new LiveSearchTApi();
    const interactionSearchTUseCase = new InteractionSearchTUseCase(liveSearchTRepository);

    return interactionSearchTUseCase.interactionSearchT(drugIds);
};

/// Hook usado para la búsqueda de tripletas
export const useInteractionSearchT = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult<InteractionSearchTResult>>({
        loading: false,
        error: undefined,
        data: undefined,
    });
    const [filters, setFilters] = useState({
        byInteractionType: "all",
        bySeverity: "all",
    });

    // Obtiene de la API las tripletas para cierto IDs de drogas
    const getInteractions = useCallback((drugIds: number[]) => {
        setState({ loading: true});
        _getInteractions(drugIds).then(([res, error]) => {
            setState({
                loading: false,
                error: error?.message,
                data: res ?? undefined,
            });
        });
    }, []);

    // Mantiene todas las tripletas filtradas
    const filteredInteractions = useMemo(() => {
        let result = Object.assign({}, state.data);

        if (filters.bySeverity !== "all") {
            const bs = filters.bySeverity;
            const sevId = bs === "major" ? 1 : bs === "moderate" ? 2 : bs === "minor" ? 3 : 0;
            result.drugTripletInteractions = result?.drugTripletInteractions?.filter((d) => d.severityId12 === sevId);
        }

        if (filters.byInteractionType !== "all") {
            const ti = filters.byInteractionType;
            if (ti !== "drugs") result.drugTripletInteractions = undefined;
        }
        
        return result;        
    }, [filters.byInteractionType, filters.bySeverity, state.data]);

    // Mantiene todas las drogas que se encuentran en las tripletas.
    const filteredByDrugs = useMemo(() => {
        return Array.from(new Map([
            ...(filteredInteractions?.drugTripletInteractions ?? []).map(i => i.drug1),
            ...(filteredInteractions?.drugTripletInteractions ?? []).map(i => i.drug2),
            ...(filteredInteractions?.drugTripletInteractions ?? []).map(i => i.drug3),
        ].map(o => [o?.id, o])).values());
    }, [filteredInteractions]);


    return { getInteractions, setFilters, state, filteredInteractions, filteredByDrugs };
};