import { useCallback, useState } from "react";

import { CommentApi } from "data";
import { Comment, CommentResult } from "domain/entities/comment";
import GetAllUseCase from "domain/use-cases/comment/GetAllUseCase";
import CreateUseCase from "domain/use-cases/comment/CreateUseCase";
import DeleteUseCase from "domain/use-cases/comment/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los comentarios registrados.
const _getAllComments = () => {
    const commentRepository = new CommentApi();
    const getAllUseCase = new GetAllUseCase(commentRepository);

    return getAllUseCase.getAllComments();
};

// Retorna una promesa para agregar un comentario desde la API.
const _createComment = (comment: Comment, token: TokenPromise) => {
    const commentRepository = new CommentApi();
    const createUseCase = new CreateUseCase(commentRepository);

    return createUseCase.createComment(comment, token);
};

// Retorna una promesa para eliminar desde la API un comentario por su ID.
const _deleteComment = (commentId: number, token: TokenPromise) => {
    const commentRepository = new CommentApi();
    const deleteUseCase = new DeleteUseCase(commentRepository);

    return deleteUseCase.deleteComment(commentId, token);
};

// Hook usado para gestionar el CRUD de comentarios.
export const useComments = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<CommentResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas los comentarios registrados.
    const getAllComments = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllComments().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda un comentario desde API
    const saveComment = useCallback((comment: CommentResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createComment(comment, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                comment.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], comment]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina un comentario del estado
    const deleteComment = useCallback((commentId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteComment(commentId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== commentId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllComments, saveComment, deleteComment, state, data };
};