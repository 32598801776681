import { DrugTripletInteractionResult, DrugTripletInteraction, RequestResult, RequestBatchResult } from "domain/entities/drug-triplet-interaction";
import { Result } from "domain/entities/result";
import { DrugTripletInteractionRepository } from "domain/repositories";
import Base from "data/base";

export class DrugTripletInteractionApi implements DrugTripletInteractionRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/triplet";
    }

    /**
     * Obtiene todas las tripletas
     * @param token
     * @returns retorna un array con las tripletas
     */
    async getAllDrugTripletInteraction(token: string): Promise<[DrugTripletInteractionResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugTripletInteractionResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una tripleta por su ID
     * @param id 
     * @returns retorna una tripleta
     */
    async getDrugTripletInteraction(id: number): Promise<[null, Error] | [DrugTripletInteractionResult, null]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugTripletInteractionResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una tripleta
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugTripletInteraction(interaction: DrugTripletInteraction, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dru_id1: interaction.drugId1,
                dru_id2: interaction.drugId2,
                dru_id3: interaction.drugId3,
                dse_id12: interaction.severityId12,
                dse_id13: interaction.severityId13,
                dse_id23: interaction.severityId23,
                dit_relation12: interaction.relation12,
                dit_relation13: interaction.relation13,
                dit_relation23: interaction.relation23,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un lote de tripletas
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugTripletInteractionBatch(interactions: DrugTripletInteraction[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: interactions.map(i => ({
                    dru_id1: i.drugId1,
                    dru_id2: i.drugId2,
                    dru_id3: i.drugId3,
                    dse_id12: i.severityId12,
                    dse_id13: i.severityId13,
                    dse_id23: i.severityId23,
                    dit_relation12: i.relation12,
                    dit_relation13: i.relation13,
                    dit_relation23: i.relation23,
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una tripleta
     * @param id 
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrugTripletInteraction(id: number, interaction: DrugTripletInteraction, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dru_id1: interaction.drugId1,
                dru_id2: interaction.drugId2,
                dru_id3: interaction.drugId3,
                dse_id12: interaction.severityId12,
                dse_id13: interaction.severityId13,
                dse_id23: interaction.severityId23,
                dit_relation12: interaction.relation12,
                dit_relation13: interaction.relation13,
                dit_relation23: interaction.relation23,
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una tripleta
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrugTripletInteraction(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugTripletInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}