import { DrugDrugInteraction, RequestResult } from "domain/entities/drug-drug-interaction";
import { DrugDrugInteractionRepository } from "domain/repositories";

export default class CreateUseCase {
    private drugDrugInteractionRepository: DrugDrugInteractionRepository;
    
    public constructor(drugDrugInteractionRepository: DrugDrugInteractionRepository) {
        this.drugDrugInteractionRepository = drugDrugInteractionRepository
    }

    public async createDrugDrugInteraction(drugDrugInteraction: DrugDrugInteraction, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDrugInteractionRepository.createDrugDrugInteraction(drugDrugInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}