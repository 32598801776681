import { InteractionSearchTResult } from "domain/entities/live-searcht";
import { LiveSearchTRepository } from "domain/repositories";

export default class InteractionSearchTUseCase {
    private liveSearchTRepository: LiveSearchTRepository;
    
    public constructor(liveSearchTRepository: LiveSearchTRepository) {
        this.liveSearchTRepository = liveSearchTRepository
    }

    public async interactionSearchT(drugIds: number[]): Promise<([InteractionSearchTResult, null]) | ([null, Error])> {
        if (drugIds.length === 1 && drugIds[0] === 0) drugIds.pop();

        return this.liveSearchTRepository.interactionSearchT(drugIds);
    }
}