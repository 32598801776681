import React from 'react';

import JwtManager from '../JwtManager';
import { IAuthContext, IAuthContextProps, IToken } from "../types";

export const AuthContext = React.createContext<IAuthContext>({
    authState: {
        authToken: null,
        expireAt: null,
        refreshToken: null,
        refreshTokenExpireAt: null,
        isUsingRefreshToken: false,
        payload: null,
    },
    setAuthState: () => {},
});

const AuthProvider: React.FunctionComponent<IAuthContextProps> = ({
    children,
    authStorageName,
    authTimeStorageName,
    stateStorageName,
    refreshTokenName,
}) => {
    const tokenObject = React.useMemo(() => {
        return new JwtManager(authStorageName, authTimeStorageName, stateStorageName, refreshTokenName);
    }, [authStorageName, authTimeStorageName, refreshTokenName, stateStorageName]);

    const [authState, setAuthState] = React.useState<IToken>(tokenObject.initialToken());

    React.useEffect(() => {
        tokenObject.syncTokens(authState);
    }, [authState, tokenObject]);

    return (
        <AuthContext.Provider value={{authState, setAuthState}}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.defaultProps = {
    authStorageName: '_auth_token',
    authTimeStorageName: '_auth_time',
    stateStorageName: '_auth_state',
}

export default AuthProvider;