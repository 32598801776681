import { JsonObject, JsonProperty } from "json2typescript";
import { ModuleResult } from "./ModuleResult";

@JsonObject("PermissionResult")
export class PermissionResult {
    @JsonProperty("per_id", Number)
    id: number = 0;
    @JsonProperty("mod_id", Number)
    moduleId: number = 0;
    @JsonProperty("Module", ModuleResult)
    module?: ModuleResult;
    @JsonProperty("per_permission", String)
    permission: string = "";
}