import { DrugTripletInteraction, RequestBatchResult } from "domain/entities/drug-triplet-interaction";
import { DrugTripletInteractionRepository } from "domain/repositories";

export default class CreateBatchUseCase {
    private drugTripletInteractionRepository: DrugTripletInteractionRepository;
    
    public constructor(drugTripletInteractionRepository: DrugTripletInteractionRepository) {
        this.drugTripletInteractionRepository = drugTripletInteractionRepository
    }

    public async createDrugTripletInteractionBatch(drugTripletInteraction: DrugTripletInteraction[], token: Promise<[string | null, string | null]>): Promise<[RequestBatchResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugTripletInteractionRepository.createDrugTripletInteractionBatch(drugTripletInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}