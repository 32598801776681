import { useToast } from '@chakra-ui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';

import { ILinkInputProps } from './ILinkInputProps';

const components = {
    DropdownIndicator: null,
  };
  
const createOption = (label: string) => ({
    label,
    value: label,
});

export const LinkInput: FunctionComponent<ILinkInputProps> = (props) => {
    
    const [values, setValues] = useState<{label: string, value: string}[]>([]);
    const [inputValue, setInputValue] = useState('');
    const toast = useToast();

    useEffect(() => {
        setValues(props.initValue.map(u => { return {label: u, value: u} }));
    }, [props.initValue]);

    function _onchange(value: any, actionMeta: any) {
        setValues(value);
        props.onChange(value);
    }

    function _onchangeInputValue(inputValue: string) {
        setInputValue(inputValue);
    }

    function _onKeyDown(event: any) {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case 'Space':
            case " ":
                if (values.find(i => i.value === inputValue) === undefined) {
                    setValues([...values, createOption(inputValue)]);
                    props.onChange([...values, createOption(inputValue)]);
                    setInputValue('');
                } else {
                    toast({
                        title: "URL duplicada",
                        description: "Ya URL debe ser distinta a las ya ingresadas.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                }
                
                event.preventDefault();
        }
    }

    return (
        <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={_onchange}
            onInputChange={_onchangeInputValue}
            onKeyDown={_onKeyDown}
            placeholder="Ingrese una URL y presione enter..."
            value={values}
      />
    );
}