import React, { FunctionComponent } from 'react';

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { ICustomModalProps } from './ICustomModalProps';

export const CustomModal: FunctionComponent<ICustomModalProps> = (props) => {
    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={props.isOpen}
                onClose={props.onClose}
                scrollBehavior="inside"
                size="lg"
            >
                <ModalOverlay />
                <ModalContent>
                        <ModalHeader>{props.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {props.children}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={props.onClose}>Cerrar</Button>
                        </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}