import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Result")
export class Result {
    @JsonProperty("status", Number)
    status: number = 0;
    @JsonProperty("response", String)
    response: string = "";
    @JsonProperty("message", String)
    message: string = "";
}