import React, { useCallback } from 'react';
import { AuthContext } from '../context/AuthProvider';

/**
 * Hook usado para gestionar token al cerrar sesión
 */
export const useSignOut = (): () => boolean => {
    // Contexto de autenticación.
    const context = React.useContext(AuthContext);

    // Retorna cerrado de sesión.
    const signOut = useCallback(() => {
        try {
            if (context?.authState.authToken) {
                context.setAuthState((prev) => ({
                    ...prev,
                    authToken: null,
                    authTokenType: null,
                    expireAt: null,
                    payload: null,
                    refreshToken: null,
                    refreshTokenExpireAt: null,
                  }));
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }, [context]);

    return signOut;
};