import React, { FunctionComponent } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { v4 as uuid_v4 } from 'uuid';

import { IEvidenceListProps } from './IEvidenceListProps';

export const EvidenceList: FunctionComponent<IEvidenceListProps> = (props) => {

    function _getEvidences(linkList: string[]): any {
        return linkList.map((evidence: string) => {
            return <Box key={uuid_v4()}>{evidence}</Box>;
        });
    }

    return (
        <>
            <Box marginTop="5px" marginBottom="5px" maxWidth="100%">
                <Text
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="sm"
                    letterSpacing="wide"
                    color="gray.600"
                >
                    Evidencias
                        </Text>
                {props.evidences.length > 0 && props.evidences[0] !== null
                    ? <Box>{_getEvidences(props.evidences)}</Box>
                    : "Evidencia no disponible."
                }
            </Box>
        </>
    );
}