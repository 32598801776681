import { DrugDiseaseInteraction, RequestResult } from "domain/entities/drug-disease-interaction";
import { DrugDiseaseInteractionRepository } from "domain/repositories";

export default class CreateUseCase {
    private drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository;
    
    public constructor(drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository) {
        this.drugDiseaseInteractionRepository = drugDiseaseInteractionRepository
    }

    public async createDrugDiseaseInteraction(drugDiseaseInteraction: DrugDiseaseInteraction, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDiseaseInteractionRepository.createDrugDiseaseInteraction(drugDiseaseInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}