import { useCallback, useState } from "react";

import { LiveSearchApi } from "data";
import { DrugInteractionCountResult } from "domain/entities/live-search";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";
import DrugInteractionCountUseCase from "domain/use-cases/live-search/DrugInteractionCountUseCase";

// Obtiene de la API las drogas agrupadas por las cantidad de interacciones que tienen.
const _countDrugInteractions = () => {
    const liveSearchRepository = new LiveSearchApi();
    const drugInteractionCountUseCase = new DrugInteractionCountUseCase(liveSearchRepository);
    
    return drugInteractionCountUseCase.drugInteractionCount();
};

/// Hook usado para interacciones de drogas
export const useDrugInteraction = () => {
    // Estado del hook.
    const [count, setCount] = useState<IRequestResult<DrugInteractionCountResult[]>>({
        loading: false,
        error: undefined,
        data: [],
    });

    // Obtiene de la API las drogas agrupadas por sus interacciones.
    const getCount = useCallback(() => {
        setCount({ loading: true});
        _countDrugInteractions().then(([res, error]) => {
            setCount({
                loading: false,
                error: error?.message,
                data: res ?? [],
            });
        });
    }, []);

    return { getCount, count };
};