import { Disease } from "domain/entities/disease";
import { Result } from "domain/entities/result";
import { DiseaseRepository } from "domain/repositories";

export default class UpdateUseCase {
    private diseaseRepository: DiseaseRepository;
    
    public constructor(diseaseRepository: DiseaseRepository) {
        this.diseaseRepository = diseaseRepository
    }

    public async updateDisease(id: number, disease: Disease, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.diseaseRepository.updateDisease(id, disease, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}