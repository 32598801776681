import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { DrugClassResult } from "domain/entities/drug-class";
import { UserResult } from "domain/entities/user";

@JsonObject("DrugResult")
export class DrugResult {
    @JsonProperty("dru_id", Number)
    id: number = 0;
    @JsonProperty("dcl_id", Number)
    classId: number = 0;
    @JsonProperty("DrugClass", DrugClassResult)
    drugClass?: DrugClassResult;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("dru_name", String)
    name: string = "";
    @JsonProperty("dru_description", String)
    description: string = "";
    @JsonProperty("dru_tech_description", String)
    techDescription: string = "";
    @JsonProperty("dru_side_effects", String)
    sideEffects: string = "";
    @JsonProperty("dru_cas", String)
    cas: string = "";
    @JsonProperty("dru_sources", String)
    sources: string = "";
    @JsonProperty("dru_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dru_updated_at", DateConverter)
    updatedAt: Date = new Date();
}