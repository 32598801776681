import React, { FunctionComponent } from 'react';

import { Box, Flex, ScaleFade, Text } from '@chakra-ui/react';

import { EvidenceList } from 'presentation/components/EvidenceList';
import { ReferenceList } from 'presentation/components/ReferenceList';
import { IInteractionInfoProps } from './IInteractionInfoProps';

export const InteractionInfo: FunctionComponent<IInteractionInfoProps> = (props) => {

    return (
        <Box marginTop="5px">
            {!props.showTechInformation &&
                <ScaleFade initialScale={0.9} in={!props.showTechInformation}>
                    <Flex>
                        {props.effect}
                    </Flex>
                    { props.description !== null &&
                        <Flex>
                            <Box>
                                <Text
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    fontSize="sm"
                                    letterSpacing="wide"
                                    color="gray.600"
                                    marginTop="20px"
                                    marginBottom="5px"
                                >
                                    Descripción de la interacción
                                </Text>
                                {props.description}                         
                            </Box>
                        </Flex>
                    }
                </ScaleFade>
            }
            {props.showTechInformation &&
                <ScaleFade initialScale={0.9} in={props.showTechInformation}>
                    <Flex>
                        <EvidenceList
                            evidences={props.evidences}
                        />
                    </Flex>
                </ScaleFade>
            }
            <Flex marginTop="40px">
                <ReferenceList
                    references={props.references}
                />
            </Flex>
        </Box>
    );
}