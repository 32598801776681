import { useCallback, useMemo, useState } from "react";

import { LiveSearchApi } from "data";
import { InteractionSearchResult } from "domain/entities/live-search";
import InteractionSearchUseCase from "domain/use-cases/live-search/InteractionSearchUseCase";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";

// Obtiene de la API las interacciones para cierto IDs de drogas, alimentos y enfermedades.
const _getInteractions = (drugIds: number[], foodIds: number[], diseaseIds: number[]) => {
    const liveSearchRepository = new LiveSearchApi();
    const interactionSearchUseCase = new InteractionSearchUseCase(liveSearchRepository);

    return interactionSearchUseCase.interactionSearch(drugIds, diseaseIds, foodIds);
};

/// Hook usado para la búsqueda de interacciones
export const useInteractionSearch = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult<InteractionSearchResult>>({
        loading: false,
        error: undefined,
        data: undefined,
    });
    const [filters, setFilters] = useState({
        byInteractionType: "all",
        bySeverity: "all",
    });

    // Obtiene de la API las interacciones para cierto IDs de drogas, alimentos y enfermedades.
    const getInteractions = useCallback((drugIds: number[], foodIds: number[], diseaseIds: number[]) => {
        setState({ loading: true});
        _getInteractions(drugIds, foodIds, diseaseIds).then(([res, error]) => {
            setState({
                loading: false,
                error: error?.message,
                data: res ?? undefined,
            });
        });
    }, []);

    // Mantiene todas las interacciones filtradas
    const filteredInteractions = useMemo(() => {
        let result = Object.assign({}, state.data);

        if (filters.bySeverity !== "all") {
            const bs = filters.bySeverity;
            const sevId = bs === "major" ? 1 : bs === "moderate" ? 2 : bs === "minor" ? 3 : 0;
            result.drugInteractions = result?.drugInteractions?.filter((d) => d.severityId === sevId);
            result.foodInteractions = result?.foodInteractions?.filter((d) => d.severityId === sevId);
            result.diseaseInteractions = result?.diseaseInteractions?.filter((d) => d.severityId === sevId);
        }

        if (filters.byInteractionType !== "all") {
            const ti = filters.byInteractionType;
            if (ti !== "drugs") result.drugInteractions = undefined;
            if (ti !== "foods") result.foodInteractions = undefined;
            if (ti !== "diseases") result.diseaseInteractions = undefined;
        }
        
        return result;        
    }, [filters.byInteractionType, filters.bySeverity, state.data]);

    // Mantiene todas las drogas que se encuentran en las interacciones.
    const filteredByDrugs = useMemo(() => {
        return Array.from(new Map([
            ...(filteredInteractions?.drugInteractions ?? []).map(i => i.drug1),
            ...(filteredInteractions?.drugInteractions ?? []).map(i => i.drug2),
            ...(filteredInteractions?.drugInteractions ?? []).map(i => i.drug3),
            ...(filteredInteractions?.foodInteractions ?? []).map(i => i.drug),
            ...(filteredInteractions?.diseaseInteractions ?? []).map(i => i.drug),
        ].map(o => [o?.id, o])).values());
    }, [filteredInteractions]);

    // Mantiene todas los alimentos que se encuentran en las interacciones.
    const filteredByFoods = useMemo(() => {
        return Array.from(new Map([
            ...(filteredInteractions?.foodInteractions ?? []).map(i => i.food),
        ].map(o => [o?.id, o])).values());
    }, [filteredInteractions?.foodInteractions]);

    // Mantiene todas las enfermedades que se encuentran en las interacciones.
    const filteredByDiseases = useMemo(() => {
        return Array.from(new Map([
            ...(filteredInteractions?.diseaseInteractions ?? []).map(i => i.disease),
        ].map(o => [o?.id, o])).values());
    }, [filteredInteractions?.diseaseInteractions]);


    return { getInteractions, setFilters, state, filteredInteractions, filteredByDrugs, filteredByFoods, filteredByDiseases };
};