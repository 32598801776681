import { Result } from "domain/entities/result";
import { DrugRepository } from "domain/repositories";

export default class DeleteUseCase {
    private drugRepository: DrugRepository;
    
    public constructor(drugRepository: DrugRepository) {
        this.drugRepository = drugRepository
    }

    public async deleteDrug(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugRepository.deleteDrug(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}