import { LiveSearchTResult, InteractionSearchTResult } from "domain/entities/live-searcht";
import { LiveSearchTRepository } from "domain/repositories";
import Base from "data/base";

export class LiveSearchTApi implements LiveSearchTRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/searcht";
    }

    /**
     * Busca nombres de drogas
     * @param p Texto a buscar
     * @returns retorna array con los resultados
     */
    async liveSearchT(p: string): Promise<([LiveSearchTResult[], null]) | ([null, Error])> {
        try {
            const url: string = await LiveSearchTApi.rute() + "/livesearch?p=" + p;
            
            const [res, error] = await Base.getArray(url, LiveSearchTResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Busca tripletas entre un conjunto de ID de droga
     * @param drugIds 
     * @returns retorna resultados de interacciones
     */
    async interactionSearchT(drugIds: number[]): Promise<[null, Error] | [InteractionSearchTResult, null]> {
        try {
            const url: string = await LiveSearchTApi.rute() + "/triplets/";
            
            const [res, error] = await Base.post(url, {
                drug_ids: drugIds,
            }, InteractionSearchTResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }


}