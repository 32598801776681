import  React from  "react";

import { Route, Redirect } from 'wouter';
import { useAuthUser, useIsAuthenticated } from "presentation/utils/auth/hooks";
import { IUserData } from "presentation/utils/interfaces/IUserData";
import getPermission, { ModuleName } from "presentation/utils/functions/getPermission";

export const PrivateRoute: React.FC<{
        component: React.FC;
        path: string;
        module?: ModuleName;
    }> = (props) => {

    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const userInfo = auth ? auth as { data: IUserData } : null;

    return isAuthenticated
        ? props.module !== undefined
            ? getPermission(props.module, userInfo?.data.usr_permissions)
                ? (<Route path={props.path} component={props.component}/>)
                : (<Redirect to="/admin/home"/>)
            : <Route path={props.path} component={props.component}/>
        : (<Redirect to="/login"/>);
};