import { Role, RequestResult } from "domain/entities/role";
import { RoleRepository } from "domain/repositories";

export default class CreateUseCase {
    private roleRepository: RoleRepository;
    
    public constructor(roleRepository: RoleRepository) {
        this.roleRepository = roleRepository
    }

    public async createRole(role: Role, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.roleRepository.createRole(role, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}