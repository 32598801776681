import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { UserResult } from "domain/entities/user";

@JsonObject("FoodResult")
export class FoodResult {
    @JsonProperty("foo_id", Number)
    id: number = 0;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("foo_name", String)
    name: string = "";
    @JsonProperty("foo_description", String)
    description: string = "";
    @JsonProperty("foo_sources", String)
    sources: string = "";
    @JsonProperty("foo_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("foo_updated_at", DateConverter)
    updatedAt: Date = new Date();
}