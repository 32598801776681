import React, { FunctionComponent, useState } from 'react';

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';

import { IDeleteAlertDialogProps } from './IDeleteAlertDialogProps';

export const DeleteAlertDialog: FunctionComponent<IDeleteAlertDialogProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    function onDelete() {
        setIsLoading(true);
        props.onDelete();
    }

    return (
        <>
            <AlertDialog
                isOpen={props.isOpen}
                leastDestructiveRef={cancelRef}
                onClose={props.onCancel}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            { props.title ?? "Eliminar" }
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            { props.content ?? "¿Estás seguro? No puede deshacer esta acción después." }
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button 
                                ref={cancelRef} 
                                onClick={props.onCancel}
                                isDisabled={isLoading}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                colorScheme="red" 
                                onClick={onDelete} 
                                ml={3}
                                loadingText="Eliminando..."
                                isLoading={isLoading}
                            >
                                Eliminar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}