import { UserResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";

export default class GetAllUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async getAllUsers(token: Promise<[string | null, string | null]>): Promise<([UserResult[], null]) | ([null, Error])> {
        const [t, ] = await token;
        if (t !== null) {
            return this.userRepository.getAllUsers(t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}