import { DrugClassResult, DrugClass, RequestResult, RequestBatchResult } from "domain/entities/drug-class";
import { Result } from "domain/entities/result";
import { DrugClassRepository } from "domain/repositories";
import Base from "data/base";

export class DrugClassApi implements DrugClassRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/classes";
    }

    /**
     * Obtiene todas las clases de droga
     * @returns retorna un array con las clases de droga
     */
    async getAllDrugClass(): Promise<[DrugClassResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugClassApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugClassResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una clase de droga por su ID
     * @param id 
     * @returns retorna clase de droga
     */
    async getDrugClass(id: number): Promise<[null, Error] | [DrugClassResult, null]> {
        try {
            const url: string = await DrugClassApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugClassResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una clase de droga
     * @param drugClass 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugClass(drugClass: DrugClass, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugClassApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dcl_name: drugClass.name,
                dcl_description: drugClass.description
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea en lote clase de drogas
     * @param drugClasses 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugClassBatch(drugClasses: DrugClass[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugClassApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: drugClasses.map(d => ({
                    dcl_name: d.name,
                    dcl_description: d.description
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una clase de droga
     * @param id 
     * @param drugClass 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrugClass(id: number, drugClass: DrugClass, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugClassApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dcl_name: drugClass.name,
                dcl_description: drugClass.description
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una clase de droga
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrugClass(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugClassApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}