import { Result } from "domain/entities/result";
import { FoodRepository } from "domain/repositories";

export default class DeleteUseCase {
    private foodRepository: FoodRepository;
    
    public constructor(foodRepository: FoodRepository) {
        this.foodRepository = foodRepository
    }

    public async deleteFood(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.foodRepository.deleteFood(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}