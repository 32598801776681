import { LiveSearchResult } from "domain/entities/live-search";
import { LiveSearchRepository } from "domain/repositories";

export default class AutocompleteUseCase {
    private liveSearchRepository: LiveSearchRepository;
    
    public constructor(liveSearchRepository: LiveSearchRepository) {
        this.liveSearchRepository = liveSearchRepository
    }

    public async liveSearch(p: string): Promise<([{drugs: LiveSearchResult[], foods: LiveSearchResult[], diseases: LiveSearchResult[]}, null]) | ([null, Error])> {
        if (p.length > 0) {
            const [result, error] = await this.liveSearchRepository.liveSearch(p);
            if (result !== null && error === null) {
                const drugs = result.filter(item => item.type === "drug");
                const foods = result.filter(item => item.type === "food");
                const diseases = result.filter(item => item.type === "disease");
                return [{drugs: drugs, foods: foods, diseases: diseases}, null];
            } 
            
            if (error !== null) {
                return [null, error];
            }

            return [null, new Error("Error desconocido")];
            
        } else {
            return [{drugs: [], foods: [], diseases: []}, null];
        }
        
    }
}