import { DrugClassResult } from "domain/entities/drug-class";
import { DrugClassRepository } from "domain/repositories";

export default class GetAllUseCase {
    private drugClassRepository: DrugClassRepository;
    
    public constructor(drugClassRepository: DrugClassRepository) {
        this.drugClassRepository = drugClassRepository
    }

    public async getAllDrugClasses(): Promise<[DrugClassResult[], null] | [null, Error]> {
        return this.drugClassRepository.getAllDrugClass();
    }
}