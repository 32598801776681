import { InteractionSearchResult } from "domain/entities/live-search";
import { LiveSearchRepository } from "domain/repositories";

export default class InteractionSearchUseCase {
    private liveSearchRepository: LiveSearchRepository;
    
    public constructor(liveSearchRepository: LiveSearchRepository) {
        this.liveSearchRepository = liveSearchRepository
    }

    public async interactionSearch(drugIds: number[], diseaseIds: number[], foodIds: number[]): Promise<([InteractionSearchResult, null]) | ([null, Error])> {
        if (drugIds.length === 1 && drugIds[0] === 0) drugIds.pop();
        if (foodIds.length === 1 && foodIds[0] === 0) foodIds.pop();
        if (diseaseIds.length === 1 && diseaseIds[0] === 0) diseaseIds.pop();

        return this.liveSearchRepository.interactionSearch(drugIds, diseaseIds, foodIds);
    }
}