import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("LiveSearchTResult")
export class LiveSearchTResult {
    @JsonProperty("itet_id", Number)
    id: number = 0;
    @JsonProperty("itet_name", String)
    name: string = "";
    @JsonProperty("itet_type", String)
    type: string = "";

}
