import React, { useMemo } from 'react';
import { AuthContext } from '../context/AuthProvider';

/**
 * Comprueba si el usuario está autenticado o no.
 */
export const useIsAuthenticated = () => {
    // Contexto de autenticación.
    const context = React.useContext(AuthContext);
    const authState = context.authState;
    
    // Retorna si el usuario esta autenticado o no.
    const isAuthenticated = useMemo(() => {
        if (authState.authToken && authState.expireAt) {
            if (new Date(authState.expireAt) > new Date()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [authState.authToken, authState.expireAt]);

    return isAuthenticated;
};