import { RoleResult } from "domain/entities/role";
import { RoleRepository } from "domain/repositories";

export default class GetAllUseCase {
    private roleRepository: RoleRepository;
    
    public constructor(roleRepository: RoleRepository) {
        this.roleRepository = roleRepository
    }

    public async getAllRoles(): Promise<[RoleResult[], null] | [null, Error]> {
        return this.roleRepository.getAllRoles();
    }
}