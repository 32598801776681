import { useIsAuthenticated } from "presentation/utils/auth/hooks";
import React from "react";

import { Redirect } from 'wouter';

export const DefaultRoute: React.FC<{
    defaultPath: string;
    adminPath: string;
}> = (props) => {

    const isAuthenticated = useIsAuthenticated();

    return isAuthenticated ? (<Redirect to={props.adminPath} />) : (<Redirect to={props.defaultPath} />);
};