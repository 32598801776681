import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Data")
class Data {
    @JsonProperty("access_token", String)
    accessToken: string = "";
    @JsonProperty("refresh_token", String)
    refreshToken: string = "";
}

@JsonObject("LoginResult")
export class LoginResult {
    @JsonProperty("status", Number)
    status: number = 0;
    @JsonProperty("response", String)
    response: string = "";
    @JsonProperty("message", String)
    message: string = "";
    @JsonProperty("data", Data)
    data?: Data;
}