import { useCallback, useState } from "react";

import { DrugTripletInteractionApi } from "data";
import { DrugTripletInteraction, DrugTripletInteractionResult } from "domain/entities/drug-triplet-interaction";
import GetAllUseCase from "domain/use-cases/drug-triplet-interaction/GetAllUseCase";
import CreateUseCase from "domain/use-cases/drug-triplet-interaction/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/drug-triplet-interaction/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/drug-triplet-interaction/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/drug-triplet-interaction/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todas las tripletas registradas.
const _getAllDrugTripletInteractions = (token: TokenPromise) => {
    const interactionRepository = new DrugTripletInteractionApi();
    const getAllUseCase = new GetAllUseCase(interactionRepository);

    return getAllUseCase.getAllDrugTripletInteractions(token);
};

// Retorna una promesa para agregar una tripleta desde la API.
const _createDrugTripletInteraction = (interaction: DrugTripletInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugTripletInteractionApi();
    const createUseCase = new CreateUseCase(interactionRepository);

    return createUseCase.createDrugTripletInteraction(interaction, token);
};

// Retorna una promesa para agregar un lote de tripletas desde la API.
const _createDrugTripletInteractionBatch = (interactions: DrugTripletInteraction[], token: TokenPromise) => {
    const interactionRepository = new DrugTripletInteractionApi();
    const createBatchUseCase = new CreateBatchUseCase(interactionRepository);

    return createBatchUseCase.createDrugTripletInteractionBatch(interactions, token);
};

// Retorna una promesa para editar una tripleta desde la API.
const _editDrugTripletInteraction = (interactionId: number, interaction: DrugTripletInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugTripletInteractionApi();
    const updateUseCase = new UpdateUseCase(interactionRepository);

    return updateUseCase.updateDrugTripletInteraction(interactionId, interaction, token);
};

// Retorna una promesa para eliminar desde la API una tripleta por su ID.
const _deleteDrugTripletInteraction = (interactionId: number, token: TokenPromise) => {
    const interactionRepository = new DrugTripletInteractionApi();
    const deleteUseCase = new DeleteUseCase(interactionRepository);

    return deleteUseCase.deleteDrugTripletInteraction(interactionId, token);
};

// Hook usado para gestionar el CRUD de tripletas.
export const useDrugTripletInteractions = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DrugTripletInteractionResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    const checkExists = useCallback(async (interaction: DrugTripletInteraction) => {
        const druID1 = interaction.drugId1 ?? null;
        const druID2 = interaction.drugId2 ?? null;
        const druID3 = interaction.drugId3 ?? null;
        const exists = data.find(i => i.drugId1 === druID1 && i.drugId2 === druID2 && i.drugId3 === druID3) !== undefined;
        await new Promise(r => setTimeout(r, 1000));
        if (exists) {
            isUpdateStateOk(new Error("Combinaciones de droga ya existe"));
            return true;
        } else {
            return false;
        }
    }, [data, isUpdateStateOk]);

    // Obtiene de la API todas las tripletas registradas.
    const getAllDrugTripletInteractions = useCallback(() => {
        setState({ dataLoading: true, loading: false });
        _getAllDrugTripletInteractions(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda una tripleta desde API
    const saveDrugTripletInteraction = useCallback((interaction: DrugTripletInteractionResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        checkExists(interaction).then((exists) => {
            if (!exists) {
                _createDrugTripletInteraction(interaction, getTokens()).then(([res, error]) => {
                    if (isUpdateStateOk(error)) {
                        interaction.id = res?.data?.id ?? 0;
                        setData((prev) => ([...prev ?? [], interaction]));
                    }
                });
            }
        });
    }, [checkExists, getTokens, isUpdateStateOk]);

    // Guarda un lote de tripletas desde API
    const saveDrugTripletInteractionBatch = useCallback((interactions: DrugTripletInteractionResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));

        _createDrugTripletInteractionBatch(interactions, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDrugTripletInteractions();
            }
        });
    }, [getAllDrugTripletInteractions, getTokens, isUpdateStateOk]);

    // Edita una tripleta desde API
    const editDrugTripletInteraction = useCallback((interaction: DrugTripletInteractionResult) => {
        console.log(interaction);
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDrugTripletInteraction(interaction.id, interaction, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === interaction.id ? interaction : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una tripleta del estado
    const deleteDrugTripletInteraction = useCallback((interactionId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrugTripletInteraction(interactionId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== interactionId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllDrugTripletInteractions, saveDrugTripletInteraction, saveDrugTripletInteractionBatch, editDrugTripletInteraction, deleteDrugTripletInteraction, state, data };
};