import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { RoleResult } from "domain/entities/role/structures/RoleResult";

@JsonObject("UserResult")
export class UserResult {
    @JsonProperty("usr_id", Number)
    id: number = 0;
    @JsonProperty("rol_id", Number)
    rolId: number = 0;
    @JsonProperty("rol", RoleResult)
    rol?: RoleResult;
    @JsonProperty("usr_email", String)
    email: string = "";
    @JsonProperty("usr_name", String)
    name: string = "";
    @JsonProperty("usr_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("usr_updated_at", DateConverter)
    updatedAt: Date = new Date();

    password: string = "";
}