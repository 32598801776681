import { FoodResult } from "domain/entities/food";
import { FoodRepository } from "domain/repositories";

export default class GetAllUseCase {
    private foodRepository: FoodRepository;
    
    public constructor(foodRepository: FoodRepository) {
        this.foodRepository = foodRepository
    }

    public async getAllFoods(): Promise<[FoodResult[], null] | [null, Error]> {
        return this.foodRepository.getAllFoods();
    }
}