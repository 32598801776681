import { useCallback, useState } from "react";

import { DrugFoodInteractionApi } from "data";
import { DrugFoodInteraction, DrugFoodInteractionResult } from "domain/entities/drug-food-interaction";
import GetAllUseCase from "domain/use-cases/drug-food-interaction/GetAllUseCase";
import CreateUseCase from "domain/use-cases/drug-food-interaction/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/drug-food-interaction/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/drug-food-interaction/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/drug-food-interaction/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todas las interacciones drogas-alimento registrados.
const _getAllDrugFoodInteractions = (token: TokenPromise) => {
    const interactionRepository = new DrugFoodInteractionApi();
    const getAllUseCase = new GetAllUseCase(interactionRepository);

    return getAllUseCase.getAllDrugFoodInteractions(token);
};

// Retorna una promesa para agregar una interaction droga-alimento desde la API.
const _createDrugFoodInteraction = (interaction: DrugFoodInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugFoodInteractionApi();
    const createUseCase = new CreateUseCase(interactionRepository);

    return createUseCase.createDrugFoodInteraction(interaction, token);
};

// Retorna una promesa para agregar un lote de interactiones droga-alimento desde la API.
const _createDrugFoodInteractionBatch = (interactions: DrugFoodInteraction[], token: TokenPromise) => {
    const interactionRepository = new DrugFoodInteractionApi();
    const createBatchUseCase = new CreateBatchUseCase(interactionRepository);

    return createBatchUseCase.createDrugFoodInteractionBatch(interactions, token);
};

// Retorna una promesa para editar una interacción droga-alimento desde la API.
const _editDrugFoodInteraction = (interactionId: number, interaction: DrugFoodInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugFoodInteractionApi();
    const updateUseCase = new UpdateUseCase(interactionRepository);

    return updateUseCase.updateDrugFoodInteraction(interactionId, interaction, token);
};

// Retorna una promesa para eliminar desde la API una interacción droga-alimento por su ID.
const _deleteDrugFoodInteraction = (interactionId: number, token: TokenPromise) => {
    const interactionRepository = new DrugFoodInteractionApi();
    const deleteUseCase = new DeleteUseCase(interactionRepository);

    return deleteUseCase.deleteDrugFoodInteraction(interactionId, token);
};

// Hook usado para gestionar el CRUD de interacciones droga-alimentos.
export const useDrugFoodInteractions = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DrugFoodInteractionResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    const checkExists = useCallback(async (interaction: DrugFoodInteraction) => {
        const druID = interaction.drugId ?? null;
        const fooID = interaction.foodId ?? null;
        const exists = data.find(i => i.drugId === druID && i.foodId === fooID) !== undefined;
        await new Promise(r => setTimeout(r, 1000));
        if (exists) {
            isUpdateStateOk(new Error("Combinaciones de droga ya existe"));
            return true;
        } else {
            return false;
        }
    }, [data, isUpdateStateOk]);

    // Obtiene de la API todas las interacciones droga-alimento registradas.
    const getAllDrugFoodInteractions = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllDrugFoodInteractions(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda una interacción droga-alimento desde API
    const saveDrugFoodInteraction = useCallback((interaction: DrugFoodInteractionResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        checkExists(interaction).then((exists) => {
            if (!exists) {
                _createDrugFoodInteraction(interaction, getTokens()).then(([res, error]) => {
                    if (isUpdateStateOk(error)) {
                        interaction.id = res?.data?.id ?? 0;
                        setData((prev) => ([...prev ?? [], interaction]));
                    }
                });
            }
        });
    }, [checkExists, getTokens, isUpdateStateOk]);

    // Guarda un lote de interacciones droga-alimento desde API
    const saveDrugFoodInteractionBatch = useCallback((interactions: DrugFoodInteractionResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDrugFoodInteractionBatch(interactions, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDrugFoodInteractions();
            }
        });
    }, [getAllDrugFoodInteractions, getTokens, isUpdateStateOk]);

    // Edita una interacción droga-alimento desde API
    const editDrugFoodInteraction = useCallback((interaction: DrugFoodInteractionResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDrugFoodInteraction(interaction.id, interaction, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === interaction.id ? interaction : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una interacción droga-alimento del estado
    const deleteDrugFoodInteraction = useCallback((interactionId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrugFoodInteraction(interactionId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== interactionId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllDrugFoodInteractions, saveDrugFoodInteraction, saveDrugFoodInteractionBatch, editDrugFoodInteraction, deleteDrugFoodInteraction, state, data };
};