import { DrugFoodInteractionResult } from "domain/entities/drug-food-interaction";
import { DrugFoodInteractionRepository } from "domain/repositories";

export default class GetAllUseCase {
    private drugFoodInteractionRepository: DrugFoodInteractionRepository;
    
    public constructor(drugFoodInteractionRepository: DrugFoodInteractionRepository) {
        this.drugFoodInteractionRepository = drugFoodInteractionRepository
    }

    public async getAllDrugFoodInteractions(token: Promise<[string | null, string | null]>): Promise<[DrugFoodInteractionResult[], null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugFoodInteractionRepository.getAllDrugFoodInteraction(t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}