import { Food, RequestResult } from "domain/entities/food";
import { FoodRepository } from "domain/repositories";

export default class CreateUseCase {
    private foodRepository: FoodRepository;
    
    public constructor(foodRepository: FoodRepository) {
        this.foodRepository = foodRepository
    }

    public async createFood(food: Food, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.foodRepository.createFood(food, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}