import { Result } from "domain/entities/result";
import { UserRepository } from "domain/repositories";

export default class DeleteUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async deleteUser(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.userRepository.deleteUser(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}