import { LiveSearchTResult } from "domain/entities/live-searcht";
import { LiveSearchTRepository } from "domain/repositories";

export default class AutocompleteUseCase {
    private liveSearchTRepository: LiveSearchTRepository;
    
    public constructor(liveSearchTRepository: LiveSearchTRepository) {
        this.liveSearchTRepository = liveSearchTRepository
    }

    public async liveSearchT(p: string): Promise<([{drugs: LiveSearchTResult[]}, null]) | ([null, Error])> {
        if (p.length > 0) {
            const [result, error] = await this.liveSearchTRepository.liveSearchT(p);
            if (result !== null && error === null) {
                const drugs = result.filter(item => item.type === "drug");
                return [{drugs: drugs}, null];
            } 
            
            if (error !== null) {
                return [null, error];
            }

            return [null, new Error("Error desconocido")];
            
        } else {
            return [{drugs: []}, null];
        }
        
    }
}