import { CommentResult } from "domain/entities/comment";
import { CommentRepository } from "domain/repositories";

export default class GetAllUseCase {
    private commentRepository: CommentRepository;
    
    public constructor(commentRepository: CommentRepository) {
        this.commentRepository = commentRepository
    }

    public async getAllComments(): Promise<[CommentResult[], null] | [null, Error]> {
        return this.commentRepository.getAllComments();
    }
}