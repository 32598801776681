import React, { FunctionComponent } from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

import { ElementInfo } from 'presentation/components/ElementInfo';

import { ReactComponent as FoodIcon } from "./food1-icon.svg";
import { IFoodInfoBoxProps } from './IFoodInfoBoxProps';

export const FoodInfoBox: FunctionComponent<IFoodInfoBoxProps> = (props) => {

    function _getLinks(links?: string): any {
        return links?.split(" ") ?? [];
    }

    return (
        <>
            { props.food &&
                <Box bg="#ffffff" p={{base: "15px", md: "20px"}} m="5px" marginBottom="20px" boxShadow="0 10px 10px -5px #ebebff" borderRadius="10px" border="1px" borderColor="#f7f7ff">
                    <Flex marginBottom="10px">
                        <Box d="flex">
                            <FoodIcon width="35px" />
                        </Box>
                        <Box d="flex">
                            <Heading as="h4" size="md" textAlign="center">
                                {props.food.name}
                            </Heading>
                        </Box>
                    </Flex>
                    <ElementInfo
                        description={props.food.description}
                        sources={_getLinks(props.food.sources)}
                        showTechInformation={false}
                    />
                </Box>
            }
        </>
    );
}