import React, { FunctionComponent, useState } from 'react';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import { Box, Button, Center, Flex } from '@chakra-ui/react';
import ReactJoyride, { STATUS } from 'react-joyride';

import { ISearchProps } from './ISearchProps';

import { ReactComponent as DrugIcon } from "./pill-icon.svg";
import { ReactComponent as FoodIcon } from "./food1-icon.svg";
import { ReactComponent as DiseaseIcon } from "./disease-icon.svg";
import tutorialGif from './tutorial_01.gif';
import { useSearchAutocomplete } from 'presentation/view-models/hooks';

const customStyles = {
    menu: (provided: any, state: any) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.selectProps.menuColor,
    }),

    control: (styles: any) => ({
        ...styles,
        borderRadius: '10px 0px 0px 10px',
        borderRight: '0px',
        minHeight: '50px',
        boxShadow: '-5px 4px 8px 0 rgba(0, 0, 0, 0.2), -10px 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }),

    option: (styles: any, { isDisabled }: any) => {
        return {
            ...styles,
            color: isDisabled
                ? '#ccc'
                : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },

    multiValue: (base: any) => {
        return {
            ...base,
            background: "#EBF8FF",
            padding: 0,
        }
    },

    multiValueLabel: (base: any) => {
        return {
            ...base,
            padding: 0,
            paddingLeft: 0,
        }
    },

    multiValueRemove: (base: any) => {
        return {
            ...base,
            color: "#3182CE",
        }
    },
}

const { MultiValueLabel } = components;

const MultiValueItem = (props: any) => (
    <MultiValueLabel {...props}>
        <Flex color="black">
            <Box flex="1" bg="#BEE3F8" p="2px">
                {props.data.icon}
            </Box>
            <Box flex="3" p="2px" paddingLeft="5px" paddingRight="5px">
            {props.data.label}
            </Box>
        </Flex>
    </MultiValueLabel>
);

export const Search: FunctionComponent<ISearchProps> = (props) => {
    const { selected, setSelectedValues, getSuggestions } = useSearchAutocomplete();
    const [runTour, setRunTour] = useState(false);
    const [steps] = useState([
        {
            target: '.search_box_helper',
            content: (
                <div>
                    <img src={tutorialGif} alt="pasos" style={{ marginBottom: 10 }} />
                    <p>Escribe una lista de todos los medicamentos, alimentos o enfermedades que desees buscar</p>
                </div>
            ),
            disableBeacon: true,
            spotlightPadding: 15,
        },
    ]);

    function _handleChange(selectedOptions: ValueType<{ label: string; value: string }, true>) {
        setSelectedValues(selectedOptions);
    }

    function promiseOptions(inputValue: string): Promise<any> {
        return new Promise(async resolve => {
            const [result, error] = await getSuggestions(inputValue);
            if (error !== null) console.log(error);
            if (result !== null) {
                resolve([
                    {
                        label: 'Fármacos', options: result.drugs.map(i => {
                            return { value: `${i.id};${i.type}`, label: i.name, icon: <DrugIcon width="20px" /> }
                        }),
                    },
                    {
                        label: 'Enfermedades', options: result.diseases.map(i => {
                            return { value: `${i.id};${i.type}`, label: i.name, icon: <DiseaseIcon width="20px" /> }
                        }),
                    },
                    {
                        label: 'Alimentos', options: result.foods.map(i => {
                            return { value: `${i.id};${i.type}`, label: i.name, icon: <FoodIcon width="20px" /> }
                        }),
                    },
                ]);
            }
        });
    }

    const _onClickTour = () => {
        if(window.localStorage.getItem('ddi_tour_home_search_finish') === null) {
            setRunTour(true);
        }
    }

    return (
        <Flex>
            <ReactJoyride
                steps={steps}
                run={runTour}
                continuous={true}
                scrollToFirstStep={true}
                locale={{ back: 'Atras', close: 'Cerrar', last: 'Lo entiendo!', next: 'Siguiente', skip: 'Saltar' }}
                callback={({ status }) => {
                    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
                        window.localStorage.setItem('ddi_tour_home_search_finish', 'true');
                        setRunTour(false);
                    }
                }}
            />
            <Box flex="1" className="search_box_helper" onClick={() => _onClickTour()} onTouchEnd={() => _onClickTour()}>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isMulti
                    onChange={_handleChange}
                    loadOptions={promiseOptions}
                    loadingMessage={() => "Cargando información..."}
                    noOptionsMessage={() => "Ningún elemento encontrado!"}
                    components={{ MultiValueLabel: MultiValueItem,  }}
                    styles={customStyles}
                    placeholder="Escribe fármacos, enfermedades o alimentos..."
                />
            </Box>
            <Center marginLeft="-6px" bg="white" border="1px" borderColor="#cccccc" borderTopRightRadius="10px" borderBottomRightRadius="10px" paddingLeft="6px" paddingRight="5px" boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'>
                <Button onClick={() => props.onSearch(selected.data ?? [])} colorScheme="blue" style={{ cursor: 'pointer' }}>Buscar</Button>
            </Center>
        </Flex>
    );
}