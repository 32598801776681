import { useCallback, useState } from "react";

import { FoodApi } from "data";
import { Food, FoodResult } from "domain/entities/food";
import GetAllUseCase from "domain/use-cases/food/GetAllUseCase";
import CreateUseCase from "domain/use-cases/food/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/food/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/food/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/food/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los alimentos registrados.
const _getAllFoods = () => {
    const foodRepository = new FoodApi();
    const getAllUseCase = new GetAllUseCase(foodRepository);

    return getAllUseCase.getAllFoods();
};

// Retorna una promesa para agregar un alimento desde la API.
const _createFood = (food: Food, token: TokenPromise) => {
    const foodRepository = new FoodApi();
    const createUseCase = new CreateUseCase(foodRepository);

    return createUseCase.createFood(food, token);
};

// Retorna una promesa para agregar un lote de alimentos desde la API.
const _createFoodBatch = (foods: Food[], token: TokenPromise) => {
    const foodRepository = new FoodApi();
    const createBatchUseCase = new CreateBatchUseCase(foodRepository);

    return createBatchUseCase.createFoodBatch(foods, token);
};

// Retorna una promesa para editar un alimento desde la API.
const _editFood = (foodId: number, food: Food, token: TokenPromise) => {
    const foodRepository = new FoodApi();
    const updateUseCase = new UpdateUseCase(foodRepository);

    return updateUseCase.updateFood(foodId, food, token);
};

// Retorna una promesa para eliminar desde la API un alimento por su ID.
const _deleteDrug = (foodId: number, token: TokenPromise) => {
    const foodRepository = new FoodApi();
    const deleteUseCase = new DeleteUseCase(foodRepository);

    return deleteUseCase.deleteFood(foodId, token);
};

// Hook usado para gestionar el CRUD de comidas.
export const useFoods = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<FoodResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas los alimentos registradas.
    const getAllFoods = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllFoods().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda un alimento desde API
    const saveFood = useCallback((food: FoodResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createFood(food, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                food.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], food]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Guarda un lote de alimentos desde API
    const saveFoodBatch = useCallback((foods: FoodResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createFoodBatch(foods, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllFoods();
            }
        });
    }, [getAllFoods, getTokens, isUpdateStateOk]);

    // Edita un alimento desde API
    const editFood = useCallback((food: FoodResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editFood(food.id, food, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === food.id ? food : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina un alimento del estado
    const deleteFood = useCallback((foodId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrug(foodId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== foodId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllFoods, saveFood, saveFoodBatch, editFood, deleteFood, state, data };
};