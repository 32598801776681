import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";

@JsonObject("DrugClassResult")
export class DrugClassResult {
    @JsonProperty("dcl_id", Number)
    id: number = 0;
    @JsonProperty("dcl_name", String)
    name: string = "";
    @JsonProperty("dcl_description", String)
    description: string = "";
    @JsonProperty("dcl_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dcl_updated_at", DateConverter)
    updatedAt: Date = new Date();
}