import { DrugDrugInteraction } from "domain/entities/drug-drug-interaction";
import { Result } from "domain/entities/result";
import { DrugDrugInteractionRepository } from "domain/repositories";

export default class UpdateUseCase {
    private drugDrugInteractionRepository: DrugDrugInteractionRepository;
    
    public constructor(drugDrugInteractionRepository: DrugDrugInteractionRepository) {
        this.drugDrugInteractionRepository = drugDrugInteractionRepository
    }

    public async updateDrugDrugInteraction(id: number, drugDrugInteraction: DrugDrugInteraction, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDrugInteractionRepository.updateDrugDrugInteraction(id, drugDrugInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}