import { FoodResult, Food, RequestResult, RequestBatchResult } from "domain/entities/food";
import { Result } from "domain/entities/result";
import { FoodRepository } from "domain/repositories";
import Base from "data/base";

export class FoodApi implements FoodRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/foods";
    }

    /**
     * Obtiene todas los alimentos
     * @returns retorna un array con los alimentos
     */
    async getAllFoods(): Promise<[FoodResult[], null] | [null, Error]> {
        try {
            const url: string = await FoodApi.rute() + "/";

            const [res, error] = await Base.getArray(url, FoodResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene un alimento por su ID
     * @param id 
     * @returns retorna alimento
     */
    async getFood(id: number): Promise<[null, Error] | [FoodResult, null]> {
        try {
            const url: string = await FoodApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, FoodResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un alimento
     * @param food 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createFood(food: Food, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await FoodApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                foo_name: food.name,
                foo_description: food.description,
                foo_sources: food.sources
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un lote de alimentos
     * @param food 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createFoodBatch(foods: Food[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await FoodApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: foods.map(f => ({
                    foo_name: f.name,
                    foo_description: f.description,
                    foo_sources: f.sources
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza un alimento
     * @param id 
     * @param food 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateFood(id: number, food: Food, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await FoodApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                foo_name: food.name,
                foo_description: food.description,
                foo_sources: food.sources
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina un alimento
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteFood(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await FoodApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}