import { Result } from "domain/entities/result";
import { DrugDiseaseInteractionRepository } from "domain/repositories";

export default class DeleteUseCase {
    private drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository;
    
    public constructor(drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository) {
        this.drugDiseaseInteractionRepository = drugDiseaseInteractionRepository
    }

    public async deleteDrugDiseaseInteraction(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDiseaseInteractionRepository.deleteDrugDiseaseInteraction(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}