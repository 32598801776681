import { DrugDiseaseInteractionResult, DrugDiseaseInteraction, RequestResult, RequestBatchResult } from "domain/entities/drug-disease-interaction";
import { Result } from "domain/entities/result";
import { DrugDiseaseInteractionRepository } from "domain/repositories";
import Base from "data/base";

export class DrugDiseaseInteractionApi implements DrugDiseaseInteractionRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/drug-disease";
    }

    /**
     * Obtiene todas las interacciones droga-enfermedad
     * @param token
     * @returns retorna un array con las interacciones droga-enfermedad
     */
    async getAllDrugDiseaseInteraction(token: string): Promise<[DrugDiseaseInteractionResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugDiseaseInteractionResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una interacción droga-enfermedad por su ID
     * @param id 
     * @returns retorna una interacción droga-enfermedad
     */
    async getDrugDiseaseInteraction(id: number): Promise<[null, Error] | [DrugDiseaseInteractionResult, null]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugDiseaseInteractionResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una interaccion droga-enfermedad
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugDiseaseInteraction(interaction: DrugDiseaseInteraction, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dru_id: interaction.drugId,
                dis_id: interaction.diseaseId,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                ddi_effect: interaction.effect,
                ddi_description: interaction.description,
                ddi_evidence: interaction.evidence,
                ddi_evidence_source: interaction.evidenceSource,
                ddi_source: interaction.sources,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un lote de interacciones droga-enfermedad
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugDiseaseInteractionBatch(interactions: DrugDiseaseInteraction[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: interactions.map(i => ({
                    dru_id: i.drugId,
                    dis_id: i.diseaseId,
                    dse_id: i.severityId,
                    dty_id: i.methodId,
                    ddi_effect: i.effect,
                    ddi_description: i.description,
                    ddi_evidence: i.evidence,
                    ddi_evidence_source: i.evidenceSource,
                    ddi_source: i.sources,
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una interacción droga-enfermedad
     * @param id 
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrugDiseaseInteraction(id: number, interaction: DrugDiseaseInteraction, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dru_id: interaction.drugId,
                dis_id: interaction.diseaseId,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                ddi_effect: interaction.effect,
                ddi_description: interaction.description,
                ddi_evidence: interaction.evidence,
                ddi_evidence_source: interaction.evidenceSource,
                ddi_source: interaction.sources
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una interacción droga-enfermedad
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrugDiseaseInteraction(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugDiseaseInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}