import { useCallback, useState } from "react";

import { LiveSearchTApi } from "data";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";
import AutocompleteUseCase from "domain/use-cases/live-searcht/AutocompleteUseCase";
import { ISearchTResult } from "presentation/components/SearchT/ISearchTResult";
import { ValueType } from "react-select";

// Obtiene de la API sugerencias de drogas
const _getSuggestions = (value: string) => {
    const liveSearchTRepository = new LiveSearchTApi();
    const autocompleteUseCase = new AutocompleteUseCase(liveSearchTRepository);

    return autocompleteUseCase.liveSearchT(value);
};

/// Hook usado para el autocompletado en la búsqueda de drogas
export const useSearchTAutocomplete = () => {
    // Estado del hook.
    const [selected, setSelected] = useState<IRequestResult<ISearchTResult[]>>({
        loading: false,
        error: undefined,
        data: [],
    });

    // Establece los elementos drogas a buscar.
    const setSelectedValues = useCallback((options: ValueType<{ label: string; value: string }, true>) => {
        if (options !== undefined && options !== null) {
            setSelected({
                loading: false,
                error: undefined,
                data: options.map(item => {
                    return {
                        id: +item.value.split(";")[0],
                        type: item.value.split(";")[1],
                    };
                }),
            });
        }
    }, []);

    // Obtiene de la API sugerencias de drogas
    const getSuggestions = useCallback((value: string) => {
        return _getSuggestions(value); 
    }, []);

    return { getSuggestions, setSelectedValues, selected };
};