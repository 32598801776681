import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { RequestType } from "presentation/utils/interfaces/IRequestResult2";

/// Hook usado para mostrar mensajes al usuario
export const useToastMessage = () => {
    // Estado del hook.
    const toast = useToast();

    // Muestra un mesaje de exito
    const showSuccessMsg = useCallback((title: string, message: string) => {
        toast({
            title: title,
            description: message,
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    }, [toast]);
    // Muestra un mensaje de información
    const showInfoMsg = useCallback((title: string, message: string) => {
        toast({
            title: title,
            description: message,
            status: "info",
            duration: 9000,
            isClosable: true,
        });
    }, [toast]);
    // Muestra un mensaje de error
    const showErrorMsg = useCallback((message: string) => {
        toast({
            title: "Ocurrió un error",
            description: message,
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    }, [toast]);
    // Muestra mensaje basado el tipo de solicitud
    const showReqMsg = useCallback((type?: RequestType, error?: string, title?: string, message?: string) => {
        if (error) {
            showErrorMsg(error);
        } else {
            switch(type) {
                case RequestType.POST:
                    if (title && message) showSuccessMsg(title, message);
                    break;
                case RequestType.PUT:
                case RequestType.DELETE:
                    if (title && message) showInfoMsg(title, message);
            }
        }
    }, [showErrorMsg, showInfoMsg, showSuccessMsg]);

    return { showSuccessMsg, showInfoMsg, showErrorMsg, showReqMsg };
};