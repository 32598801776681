import { Result } from "domain/entities/result";
import { CommentRepository } from "domain/repositories";

export default class DeleteUseCase {
    private commentRepository: CommentRepository;
    
    public constructor(commentRepository: CommentRepository) {
        this.commentRepository = commentRepository
    }

    public async deleteComment(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.commentRepository.deleteComment(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}