import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";

@JsonObject("MethodResult")
export class MethodResult {
    @JsonProperty("dty_id", Number)
    id: number = 0;
    @JsonProperty("dty_name", String)
    name: string = "";
    @JsonProperty("dty_description", String)
    description: string = "";
    @JsonProperty("dty_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dty_updated_at", DateConverter)
    updatedAt: Date = new Date();
}