import React, { FunctionComponent } from 'react';
import { FiRepeat } from 'react-icons/fi';

import { Box, Button, Flex, IconButton, Spacer, useDisclosure } from '@chakra-ui/react';

import { DrugName } from 'presentation/components/DrugName';
import { ElementInfo } from 'presentation/components/ElementInfo';
import { IDrugInfoBoxProps } from './IDrugInfoBoxProps';

import { ReactComponent as DrugIcon } from "./pill-icon.svg";

export const DrugInfoBox: FunctionComponent<IDrugInfoBoxProps> = (props) => {

    const { isOpen, onToggle } = useDisclosure();

    function _getLinks(links?: string): any {
        return links?.split(" ") ?? [];
    }

    function _getSideEffects(effects?: string): any {
        return effects?.split(";") ?? [];
    }

    return (
        <>
            { props.drug &&
                <Box bg="#ffffff" p={{base: "15px", md: "20px"}} m="5px" marginBottom="20px" boxShadow="0 10px 10px -5px #ebebff" borderRadius="10px" border="1px" borderColor="#f7f7ff">
                    <Flex marginBottom="10px">
                        <Box d="flex">
                            <DrugIcon width="35px" />
                        </Box>
                        <Box d="flex">
                            <DrugName
                                drugName={props.drug.name}
                                drugClass={props.drug.drugClass?.name}
                                classDescription={props.drug.drugClass?.description}
                            />
                        </Box>
                        <Spacer />
                        <Box display={{ base: 'none', md: 'block' }}>
                            <Button
                                leftIcon={<FiRepeat />}
                                colorScheme="blue"
                                variant="outline"
                                onClick={onToggle}
                            >
                                Información técnica
                            </Button>
                        </Box>
                        <Box display={{ base: 'block', md: 'none' }}>
                            <IconButton
                                colorScheme="blue"
                                aria-label="Search database"
                                icon={<FiRepeat />}
                                onClick={onToggle}
                            />
                        </Box>
                    </Flex>
                    <ElementInfo
                        description={props.drug.description}
                        techDescription={props.drug.techDescription}
                        sideEffects={_getSideEffects(props.drug.sideEffects)}
                        cas={props.drug.cas}
                        sources={_getLinks(props.drug.sources)}
                        showTechInformation={isOpen}
                    />
                </Box>
            }
        </>
    );
}