import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { DrugResult } from "domain/entities//drug/structures/DrugResult";
import { SeverityResult } from "domain/entities/severity";

@JsonObject("DrugTripletInteractionResult")
export class DrugTripletInteractionResult {
    @JsonProperty("dit_id", Number)
    id: number = 0;
    @JsonProperty("dru_id1", Number)
    drugId1: number = 0;
    @JsonProperty("Drug1", DrugResult)
    drug1?: DrugResult;
    @JsonProperty("dru_id2", Number)
    drugId2: number = 0;
    @JsonProperty("Drug2", DrugResult)
    drug2?: DrugResult;
    @JsonProperty("dru_id3", Number)
    drugId3?: Number = 0;
    @JsonProperty("Drug3", DrugResult)
    drug3?: DrugResult;
    @JsonProperty("dse_id12", Number)
    severityId12: number | null = null;;
    @JsonProperty("DDISeverity12", SeverityResult)
    severity12?: SeverityResult;
    @JsonProperty("dse_id13", Number )
    severityId13: number | null = null;;
    @JsonProperty("DDISeverity13", SeverityResult)
    severity13?: SeverityResult;
    @JsonProperty("dse_id23", Number)
    severityId23: number | null = null;;
    @JsonProperty("DDISeverity23", SeverityResult)
    severity23?: SeverityResult;
    @JsonProperty("dit_relation12", String)
    relation12: string = "";
    @JsonProperty("dit_relation13", String)
    relation13: string = "";
    @JsonProperty("dit_relation23", String)
    relation23: string = "";
    @JsonProperty("dit_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dit_updated_at", DateConverter)
    updatedAt: Date = new Date();
}