import { DrugResult } from "domain/entities/drug";
import { DrugRepository } from "domain/repositories";

export default class GetAllUseCase {
    private drugRepository: DrugRepository;
    
    public constructor(drugRepository: DrugRepository) {
        this.drugRepository = drugRepository
    }

    public async getAllDrug(): Promise<[DrugResult[], null] | [null, Error]> {
        return this.drugRepository.getAllDrugs();
    }
}