import { useCallback, useState } from "react";

import { UserApi } from "data";
import { User, UserResult } from "domain/entities/user";
import GetAllUseCase from "domain/use-cases/user/GetAllUseCase";
import CreateUseCase from "domain/use-cases/user/CreateUseCase";
import UpdateUseCase from "domain/use-cases/user/UpdateUseCase";
// import UpdatePUseCase from "domain/use-cases/user/UpdatePUseCase";
import DeleteUseCase from "domain/use-cases/user/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los usuarios registrados.
const _getAllUsers = (token: TokenPromise) => {
    const userRepository = new UserApi();
    const getAllUseCase = new GetAllUseCase(userRepository);

    return getAllUseCase.getAllUsers(token);
};

// Retorna una promesa para agregar un usuario desde la API.
const _createUser = (user: User, token: TokenPromise) => {
    const userRepository = new UserApi();
    const createUseCase = new CreateUseCase(userRepository);

    return createUseCase.createUser(user, token);
};

// Retorna una promesa para editar un usuario desde la API.
const _editUser = (userId: number, user: User, token: TokenPromise) => {
    const userRepository = new UserApi();
    const updateUseCase = new UpdateUseCase(userRepository);

    return updateUseCase.updateUser(userId, user, token);
};

// // Retorna una promesa para contraseña de un usuario desde la API.
// const _editUserP = (userId: number, user: User) => {
//     const userRepository = new UserApi();
//     const updatePUseCase = new UpdatePUseCase(userRepository);

//     return updatePUseCase.updateUserP(userId, user);
// };

// Retorna una promesa para eliminar desde la API un usuario por su ID.
const _deleteUser = (userId: number, token: TokenPromise) => {
    const userRepository = new UserApi();
    const deleteUseCase = new DeleteUseCase(userRepository);

    return deleteUseCase.deleteUser(userId, token);
};

// Hook usado para gestionar el CRUD de usuarios.
export const useUsers = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<UserResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todos los usuarios registrados.
    const getAllUsers = useCallback(() => {
        setState({ dataLoading: true, loading: false });
        _getAllUsers(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda un usuario desde API
    const saveUser = useCallback((user: UserResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createUser(user, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                user.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], user]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Edita un usuario desde API
    const editUser = useCallback((user: UserResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editUser(user.id, user, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === user.id ? user : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    //   // Edita contrseña de usuario desde API
    //   const editUserP = useCallback((user: UserResult) => {
    //     setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
    //     _editUserP(user.id, user).then(([_, error]) => {
    //         if (isUpdateStateOk(error)) {
    //             setData((prev) => (prev?.map(item => item.id === user.id ? user : item)));
    //         }
    //     });
    // }, [getTokens, isUpdateStateOk]);

    // Elimina un usuario del estado
    const deleteUser = useCallback((userId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteUser(userId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== userId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllUsers, saveUser, editUser, deleteUser, state, data };
};