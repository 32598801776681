import { DrugDiseaseInteractionResult } from "domain/entities/drug-disease-interaction";
import { DrugDiseaseInteractionRepository } from "domain/repositories";

export default class GetAllUseCase {
    private drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository;
    
    public constructor(drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository) {
        this.drugDiseaseInteractionRepository = drugDiseaseInteractionRepository
    }

    public async getAllDrugDiseaseInteractions(token: Promise<[string | null, string | null]>): Promise<[DrugDiseaseInteractionResult[], null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDiseaseInteractionRepository.getAllDrugDiseaseInteraction(t);
        } else {
            return [null, new Error("Autenticación faltante")];
        } 
    }
}