import { JsonObject, JsonProperty } from "json2typescript";
import { Result } from "domain/entities/result";

@JsonObject("Data")
class Data {
    @JsonProperty("ID", Number)
    id: number = 0;
}

@JsonObject("RequestResult")
export class RequestResult extends Result {
    @JsonProperty("data", Data)
    data?: Data;
}