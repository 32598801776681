import { DrugFoodInteractionResult, DrugFoodInteraction, RequestResult, RequestBatchResult } from "domain/entities/drug-food-interaction";
import { Result } from "domain/entities/result";
import { DrugFoodInteractionRepository } from "domain/repositories";
import Base from "data/base";

export class DrugFoodInteractionApi implements DrugFoodInteractionRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/drug-food";
    }

    /**
     * Obtiene todas las interacciones droga-alimento
     * @param token
     * @returns retorna un array con las interacciones droga-alimento
     */
    async getAllDrugFoodInteraction(token: string): Promise<[DrugFoodInteractionResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugFoodInteractionResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una interacción droga-alimento por su ID
     * @param id 
     * @returns retorna una interacción droga-alimento
     */
    async getDrugFoodInteraction(id: number): Promise<[null, Error] | [DrugFoodInteractionResult, null]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugFoodInteractionResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una interaccion droga-alimento
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugFoodInteraction(interaction: DrugFoodInteraction, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dru_id: interaction.drugId,
                foo_id: interaction.foodId,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                dfi_effect: interaction.effect,
                dfi_description: interaction.description,
                dfi_evidence: interaction.evidence,
                dfi_evidence_source: interaction.evidenceSource,
                dfi_source: interaction.sources,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un lote de interacciones droga-alimento
     * @param interactions 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugFoodInteractionBatch(interactions: DrugFoodInteraction[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: interactions.map(i => ({
                    dru_id: i.drugId,
                    foo_id: i.foodId,
                    dse_id: i.severityId,
                    dty_id: i.methodId,
                    dfi_effect: i.effect,
                    dfi_description: i.description,
                    dfi_evidence: i.evidence,
                    dfi_evidence_source: i.evidenceSource,
                    dfi_source: i.sources,
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una interacción droga-alimento
     * @param id 
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrugFoodInteraction(id: number, interaction: DrugFoodInteraction, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dru_id: interaction.drugId,
                foo_id: interaction.foodId,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                dfi_effect: interaction.effect,
                dfi_description: interaction.description,
                dfi_evidence: interaction.evidence,
                dfi_evidence_source: interaction.evidenceSource,
                dfi_source: interaction.sources
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una interacción droga-alimento
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrugFoodInteraction(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugFoodInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}