import { DrugTripletInteraction, RequestResult } from "domain/entities/drug-triplet-interaction";
import { DrugTripletInteractionRepository } from "domain/repositories";

export default class CreateUseCase {
    private drugTripletInteractionRepository: DrugTripletInteractionRepository;
    
    public constructor(drugTripletInteractionRepository: DrugTripletInteractionRepository) {
        this.drugTripletInteractionRepository = drugTripletInteractionRepository
    }

    public async createDrugTripletInteraction(drugTripletInteraction: DrugTripletInteraction, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugTripletInteractionRepository.createDrugTripletInteraction(drugTripletInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}