import { Result } from "domain/entities/result";
import { DrugDrugInteractionRepository } from "domain/repositories";

export default class DeleteUseCase {
    private drugDrugInteractionRepository: DrugDrugInteractionRepository;
    
    public constructor(drugDrugInteractionRepository: DrugDrugInteractionRepository) {
        this.drugDrugInteractionRepository = drugDrugInteractionRepository
    }

    public async deleteDrugDrugInteraction(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDrugInteractionRepository.deleteDrugDrugInteraction(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}