import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("LiveSearchResult")
export class LiveSearchResult {
    @JsonProperty("ite_id", Number)
    id: number = 0;
    @JsonProperty("ite_name", String)
    name: string = "";
    @JsonProperty("ite_type", String)
    type: string = "";
}