import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("DrugInteractionCountResult")
export class DrugInteractionCountResult {
    @JsonProperty("dru_id", Number)
    drugId: number = 0;
    @JsonProperty("dru_name", String)
    drugName: string = "";
    @JsonProperty("dse_score1", Number)
    severityScore1: number = 0;
    @JsonProperty("dse_score2", Number)
    severityScore2: number = 0;
    @JsonProperty("dse_score3", Number)
    severityScore3: number = 0;
}