import { useCallback, useState } from "react";

import { RoleApi } from "data";
import { Role, RoleResult } from "domain/entities/role";
import GetAllUseCase from "domain/use-cases/role/GetAllUseCase";
import CreateUseCase from "domain/use-cases/role/CreateUseCase";
import UpdateUseCase from "domain/use-cases/role/UpdateUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los roles registrados.
const _getAllRoles = () => {
    const roleRepository = new RoleApi();
    const getAllUseCase = new GetAllUseCase(roleRepository);

    return getAllUseCase.getAllRoles();
};

// Retorna una promesa para agregar un rol desde la API.
const _createRole = (role: Role, token: TokenPromise) => {
    const roleRepository = new RoleApi();
    const createUseCase = new CreateUseCase(roleRepository);

    return createUseCase.createRole(role, token);
};

// Retorna una promesa para editar un rol desde la API.
const _editRole = (roleId: number, role: Role, token: TokenPromise) => {
    const roleRepository = new RoleApi();
    const updateUseCase = new UpdateUseCase(roleRepository);

    return updateUseCase.updateRole(roleId, role, token);
};


// Hook usado para gestionar el CRUD de roles.
export const useRoles = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<RoleResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas los roles registradas.
    const getAllRoles = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllRoles().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda un rol desde API
    const saveRole = useCallback((role: RoleResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createRole(role, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                role.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], role]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Edita un rol desde API
    const editRole = useCallback((role: RoleResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editRole(role.id, role, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === role.id ? role : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);


    return { getAllRoles, saveRole, editRole, state, data };
};