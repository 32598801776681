import { useCallback, useState } from "react";

import { LiveSearchApi } from "data";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";
import AutocompleteUseCase from "domain/use-cases/live-search/AutocompleteUseCase";
import { ISearchResult } from "presentation/components/Search/ISearchResult";
import { ValueType } from "react-select";

// Obtiene de la API sugerencias de drogas, alimentos y enfermedades.
const _getSuggestions = (value: string) => {
    const liveSearchRepository = new LiveSearchApi();
    const autocompleteUseCase = new AutocompleteUseCase(liveSearchRepository);

    return autocompleteUseCase.liveSearch(value);
};

/// Hook usado para el autocompletado en la búsqueda de drogas, alimentos y enfermedades
export const useSearchAutocomplete = () => {
    // Estado del hook.
    const [selected, setSelected] = useState<IRequestResult<ISearchResult[]>>({
        loading: false,
        error: undefined,
        data: [],
    });

    // Establece los elementos (drogas, alimentos y enfermedades) a buscar.
    const setSelectedValues = useCallback((options: ValueType<{ label: string; value: string }, true>) => {
        if (options !== undefined && options !== null) {
            setSelected({
                loading: false,
                error: undefined,
                data: options.map(item => {
                    return {
                        id: +item.value.split(";")[0],
                        type: item.value.split(";")[1],
                    };
                }),
            });
        }
    }, []);

    // Obtiene de la API sugerencias de drogas, alimentos y enfermedades.
    const getSuggestions = useCallback((value: string) => {
        return _getSuggestions(value); 
    }, []);

    return { getSuggestions, setSelectedValues, selected };
};