import { Role } from "domain/entities/role";
import { Result } from "domain/entities/result";
import { RoleRepository } from "domain/repositories";

export default class UpdateUseCase {
    private roleRepository: RoleRepository;
    
    public constructor(roleRepository: RoleRepository) {
        this.roleRepository = roleRepository
    }

    public async updateRole(id: number, role: Role, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.roleRepository.updateRole(id, role, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}