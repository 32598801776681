import { Drug, RequestResult } from "domain/entities/drug";
import { DrugRepository } from "domain/repositories";

export default class CreateUseCase {
    private drugRepository: DrugRepository;
    
    public constructor(drugRepository: DrugRepository) {
        this.drugRepository = drugRepository
    }

    public async createDrug(drug: Drug, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugRepository.createDrug(drug, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}