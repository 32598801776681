import React, { FunctionComponent, useState } from 'react';
import { Box, Flex, Heading, Spacer } from '@chakra-ui/react';
import { DrugName } from 'presentation/components/DrugName';
import { SeverityBadge } from 'presentation/components/SeverityBadge';
import { InfoModal } from './InfoModal';
import { IDrugInteractionTBoxProps } from './IDrugInteractionTBoxProps';

export const DrugInteractionTBox: FunctionComponent<IDrugInteractionTBoxProps> = (props) => {
    const [openInfoDrug1, setOpenInfoDrug1] = useState(false);
    const [openInfoDrug2, setOpenInfoDrug2] = useState(false);
    const [openInfoDrug3, setOpenInfoDrug3] = useState(false);

    function _getRelation12(relation?: string, severity?: string) {
        let color;
        switch (severity) {
            case 'leve':
                color = 'yellow';
                break;
            case 'moderada':
                color = 'orange';
                break;
            case 'grave':
                color = 'red';
                break;
            default:
                color = 'black'; // Color por defecto
        }

        switch (relation) {
            case "impl": return { symbol: " ⬈ ", color };
            case "ssi": return { symbol: " ⬈⬋ ", color };
            default: return { symbol: "-", color: 'black' };
        }
    }

    function _getRelation23(relation?: string, severity?: string) {
        let color;
        switch (severity) {
            case 'leve':
                color = 'yellow';
                break;
            case 'moderada':
                color = 'orange';
                break;
            case 'grave':
                color = 'red';
                break;
            default:
                color = 'black'; // Color por defecto
        }

        switch (relation) {
            case "impl": return { symbol: " ⬊ ", color };
            case "ssi": return { symbol: " ⬉⬊", color };
            default: return { symbol: "-", color: 'white' };
        }
    }

    function _getRelation13(relation?: string, severity?: string) {
        let color;
        switch (severity) {
            case 'leve':
                color = 'yellow';
                break;
            case 'moderada':
                color = 'orange';
                break;
            case 'grave':
                color = 'red';
                break;
            default:
                color = 'black'; // Color por defecto
        }

        switch (relation) {
            case "impl": return { symbol: " ⮕", color };
            case "ssi": return { symbol: " ⬌ ", color };
            default: return { symbol: "-", color: 'white' };
        }
    }

    // Declarar las relaciones antes del retorno
    const relation12 = _getRelation12(props.interaction.relation12, props.interaction.severity12?.name);
    const relation23 = _getRelation23(props.interaction.relation23, props.interaction.severity23?.name);
    const relation13 = _getRelation13(props.interaction.relation13, props.interaction.severity13?.name);

    return (
        <Box bg="#ffffff" p={{ base: 5, md: 19 }} m="5px" marginBottom="20px" boxShadow="0 10px 10px -5px #ebebff" borderRadius="10px" border="1px" borderColor="#f7f7ff">
           
            <Flex display={{ base: "none", md: "block" }} marginBottom={{ base: 5, md: 40 }} height="10">
                {/* Drug 1 */}
                <Box  position="relative" top="140" right="56" zIndex="1">
                    <DrugName
                        drugName={props.interaction.drug1?.name}
                        // drugClass={props.interaction.drug1?.drugClass?.name}
                        // classDescription={props.interaction.drug1?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug1(true)}
                    />
                </Box>

                {/* Relación y Severidad para Drug 1 y Drug 2 */}
                <Box position="relative" right="40" top="10" zIndex="1" >
                    <Heading as="h4" size="md" textAlign="center" color={relation12.color} fontSize="5xl">{relation12.symbol}</Heading>
                    <Box textAlign="center" position="relative" bottom="10" right="20">
                        <SeverityBadge
                            severityName={props.interaction.severity12?.name}
                            severityDescripcion={props.interaction.severity12?.description}
                        />
                    </Box>
                </Box>

                {/* Drug 2 */}
                <Box position="relative" right="20" bottom="20">
                    <DrugName
                        drugName={props.interaction.drug2?.name}
                        // drugClass={props.interaction.drug2?.drugClass?.name}
                        // classDescription={props.interaction.drug2?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug2(true)}
                    />
                </Box>

                {/* Relación y Severidad para Drug 2 y Drug 3 */}
                <Box position="relative" left="6" bottom="16" >
                    <Heading as="h4" size="md" textAlign="center" color={relation23.color} fontSize="5xl">{relation23.symbol}</Heading>
                    <Box textAlign="center" position= 'relative' bottom="10" left="20" >
                        <SeverityBadge
                            severityName={props.interaction.severity23?.name}
                            severityDescripcion={props.interaction.severity23?.description}
                        />
                    </Box>
                </Box>

                {/* Drug 3 */}
                <Box position="relative" bottom="70" left="20" zIndex="1">
                    <DrugName
                        drugName={props.interaction.drug3?.name}
                        // drugClass={props.interaction.drug3?.drugClass?.name}
                        // classDescription={props.interaction.drug3?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug3(true)}
                    />
                </Box>

                {/* Relación y Severidad para Drug 1 y Drug 3 */}
                <Box position="relative" right="20" bottom="110">
                    <Heading as="h4" size="md" textAlign="center" color={relation13.color} fontSize="5xl">{relation13.symbol}</Heading>
                    <Box textAlign="center" position="relative" >
                        <SeverityBadge
                            severityName={props.interaction.severity13?.name}
                            severityDescripcion={props.interaction.severity13?.description}
                        />
                    </Box>
                </Box>
            </Flex>

            {/* //PANTALLA MAS PEQUEÑA */}

            <Flex display={{ base: "block", md: "none" }} marginBottom={{ base: 5, md: 30 }} height="60">
                {/* Drug 1 */}
                <Box position="relative" top="140"  right="100" zIndex="1">
                    <DrugName 
                        drugName={props.interaction.drug1?.name}
                        // drugClass={props.interaction.drug1?.drugClass?.name}
                        // classDescription={props.interaction.drug1?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug1(true)}
                    />
                </Box>
                
                {/* Relación y Severidad para Drug 1 y Drug 2 */}
                <Box position="relative" right="20" top="10">
                    <Heading as="h4" size="md" textAlign="center" color={relation12.color} fontSize="5xl">{relation12.symbol}</Heading>
                    <Box textAlign="center" position="relative" bottom="10" right="20">
                        <SeverityBadge
                            severityName={props.interaction.severity12?.name}
                            severityDescripcion={props.interaction.severity12?.description}
                        />
                    </Box>
                </Box>

                {/* Drug 2 */}
                <Box position="relative" right="0" bottom="20">
                    <DrugName
                        drugName={props.interaction.drug2?.name}
                        // drugClass={props.interaction.drug2?.drugClass?.name}
                        // classDescription={props.interaction.drug2?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug2(true)}
                    />
                </Box>

                {/* Relación y Severidad para Drug 2 y Drug 3 */}
                <Box position="relative" left="20" bottom="16">
                    <Heading as="h4" size="md" textAlign="center" color={relation23.color} fontSize="5xl">{relation23.symbol}</Heading>
                    <Box textAlign="center" position= 'relative' bottom="10" left="20" >
                        <SeverityBadge
                            severityName={props.interaction.severity23?.name}
                            severityDescripcion={props.interaction.severity23?.description}
                        />
                    </Box>
                </Box>
                
                {/* Drug 3 */}
                <Box position="relative" bottom="70" left="120" zIndex="1">
                    <DrugName
                        drugName={props.interaction.drug3?.name}
                        // drugClass={props.interaction.drug3?.drugClass?.name}
                        // classDescription={props.interaction.drug3?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug3(true)}
                    />
                </Box>
                
                {/* Relación y Severidad para Drug 1 y Drug 3 */}
                <Box position="relative" right="-6" bottom="110">
                    <Heading as="h4" size="md" textAlign="center" color={relation13.color} fontSize="5xl">{relation13.symbol}</Heading>
                    <Box textAlign="center" position="relative" >
                        <SeverityBadge
                            severityName={props.interaction.severity13?.name}
                            severityDescripcion={props.interaction.severity13?.description}
                        />
                    </Box>
                </Box>
                
            </Flex>
            
            <Spacer />

            {openInfoDrug1 && (
                <InfoModal
                    drug={props.interaction.drug1}
                    isOpen={openInfoDrug1}
                    onClose={() => setOpenInfoDrug1(false)}
                />
            )}
            {openInfoDrug2 && (
                <InfoModal
                    drug={props.interaction.drug2}
                    isOpen={openInfoDrug2}
                    onClose={() => setOpenInfoDrug2(false)}
                />
            )}
            {openInfoDrug3 && (
                <InfoModal
                    drug={props.interaction.drug3}
                    isOpen={openInfoDrug3}
                    onClose={() => setOpenInfoDrug3(false)}
                />
            )}
        </Box>
        
    );
};