import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { UserResult } from "domain/entities/user";

@JsonObject("CommentResult")
export class CommentResult {
    @JsonProperty("com_id", Number)
    id: number = 0;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("com_name", String)
    name: string = "";
    @JsonProperty("com_email", String)
    email: string = "";
    @JsonProperty("com_description", String)
    description: string = "";
    @JsonProperty("com_site", String)
    site: string = "";
    @JsonProperty("com_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("com_updated_at", DateConverter)
    updatedAt: Date = new Date();
}