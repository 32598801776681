import { useCallback, useState } from "react";

import { MethodApi } from "data";
import { Method, MethodResult } from "domain/entities/method";
import GetAllUseCase from "domain/use-cases/method/GetAllUseCase";
import CreateUseCase from "domain/use-cases/method/CreateUseCase";
import UpdateUseCase from "domain/use-cases/method/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/method/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los métodos registrados.
const _getAllMethods = (token: TokenPromise) => {
    const methodRepository = new MethodApi();
    const getAllUseCase = new GetAllUseCase(methodRepository);

    return getAllUseCase.getAllMethods(token);
};

// Retorna una promesa para agregar un método desde la API.
const _createMethod = (method: Method, token: TokenPromise) => {
    const methodRepository = new MethodApi();
    const createUseCase = new CreateUseCase(methodRepository);

    return createUseCase.createMethod(method, token);
};

// Retorna una promesa para editar un método desde la API.
const _editMethod = (methodId: number, method: Method, token: TokenPromise) => {
    const methodRepository = new MethodApi();
    const updateUseCase = new UpdateUseCase(methodRepository);

    return updateUseCase.updateMethod(methodId, method, token);
};

// Retorna una promesa para eliminar desde la API un método por su ID.
const _deleteMethod = (methodId: number, token: TokenPromise) => {
    const methodRepository = new MethodApi();
    const deleteUseCase = new DeleteUseCase(methodRepository);

    return deleteUseCase.deleteMethod(methodId, token);
};

// Hook usado para gestionar el CRUD de métodos.
export const useMethods = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<MethodResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todos los métodos registradas.
    const getAllMethods = useCallback(() => {
        setState({ dataLoading: true, loading: false });
        _getAllMethods(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda un método desde API
    const saveMethod = useCallback((method: MethodResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createMethod(method, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                method.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], method]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Edita un método desde API
    const editMethod = useCallback((method: MethodResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editMethod(method.id, method, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === method.id ? method : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina un método del estado
    const deleteMethod = useCallback((methodId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteMethod(methodId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== methodId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllMethods, saveMethod, editMethod, deleteMethod, state, data };
};