
export enum RequestType {
    GET,
    POST,
    PUT,
    DELETE,
}

export interface IRequestResult2 {
    loading: boolean;
    dataLoading: boolean;
    reqCompleted?: boolean;
    reqType?: RequestType;
    error?: string;
}