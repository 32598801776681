import React, { FunctionComponent } from 'react';

import { Badge, Text, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react';

import { ISeverityBadgeProps } from './ISeverityBadgeProps';

const _getBadgeColor = (severity?: string) => {
    switch (severity) {
        case "grave": return "red";
        case "moderada": return "orange";
        case "leve": return "yellow";
        default: return "gray";
    }
};

const _getBgColor = (severity?: string) => {
    switch (severity) {
        case "grave": return "red.100";
        case "moderada": return "orange.100";
        case "leve": return "yellow.100";
        default: return "gray.100";
    }
};

const _getTextColor = (severity?: string) => {
    switch (severity) {
        case "grave": return "#822727";
        case "moderada": return"#7b341e";
        case "leve": return "#744210";
        default: return "#4f4e4e";
    }
};

export const SeverityBadge: FunctionComponent<ISeverityBadgeProps> = (props) => {

    return (
        <Popover trigger="hover">
            <PopoverTrigger>
                <Badge colorScheme={_getBadgeColor(props.severityName)}>
                    {props.severityName}
                </Badge>
            </PopoverTrigger>
            <PopoverContent bg={_getBgColor(props.severityName)}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody paddingTop="20px">
                    <Text fontSize="sm" color={_getTextColor(props.severityName)}>
                        {props.severityDescripcion}
                    </Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}