import React, { FunctionComponent } from 'react';

import { Box, Flex, ScaleFade, Text } from '@chakra-ui/react';

import { ReferenceList } from 'presentation/components/ReferenceList';
import { SideEffectList } from 'presentation/components/SideEffectList';
import { IElementInfoProps } from './IElementInfoProps';

export const ElementInfo: FunctionComponent<IElementInfoProps> = (props) => {

    return (
        <Box marginTop="5px">
            {!props.showTechInformation &&
                <ScaleFade initialScale={0.9} in={!props.showTechInformation}>
                    {props.description !== null &&
                        <Flex>
                            <Box>
                                <Text
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    fontSize="sm"
                                    letterSpacing="wide"
                                    color="gray.600"
                                    marginTop="20px"
                                    marginBottom="5px"
                                >
                                    Descripción
                                </Text>
                                <Text align="justify">{props.description}</Text>
                            </Box>
                        </Flex>
                    }
                    {props.sideEffects !== null && props.sideEffects !== undefined &&
                        <Flex marginTop="20px">
                            <SideEffectList sideEffects={props.sideEffects}/>
                        </Flex>
                    }
                </ScaleFade>
            }
            {props.showTechInformation &&
                <ScaleFade initialScale={0.9} in={props.showTechInformation}>
                    <Flex>
                        <Box>
                            <Text
                                fontWeight="bold"
                                textTransform="uppercase"
                                fontSize="sm"
                                letterSpacing="wide"
                                color="gray.600"
                                marginTop="20px"
                                marginBottom="5px"
                            >
                                Descripción técnica
                            </Text>
                            { props.techDescription !== null
                                ? <Text align="justify">{props.techDescription}</Text>
                                : "Información técnica no disponible"
                            }
                        </Box>
                    </Flex>
                </ScaleFade>
            }
            <Flex marginTop="20px">
                <ReferenceList
                    references={props.sources}
                />
            </Flex>
        </Box>
    );
}