import { LiveSearchResult, InteractionSearchResult, DrugInteractionCountResult } from "domain/entities/live-search";
import { LiveSearchRepository } from "domain/repositories";
import Base from "data/base";

export class LiveSearchApi implements LiveSearchRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/search";
    }

    /**
     * Busca nombres de drogas, enfermedades o alimento
     * @param p Texto a buscar
     * @returns retorna array con los resultados
     */
    async liveSearch(p: string): Promise<([LiveSearchResult[], null]) | ([null, Error])> {
        try {
            const url: string = await LiveSearchApi.rute() + "/livesearch?p=" + p;
            
            const [res, error] = await Base.getArray(url, LiveSearchResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Busca interacciones entre un conjunto de ID de droga, enfermedades y alimentos
     * @param drugIds 
     * @param diseaseIds 
     * @param foodIds 
     * @returns retorna resultados de interacciones
     */
    async interactionSearch(drugIds: number[], diseaseIds: number[], foodIds: number[]): Promise<[null, Error] | [InteractionSearchResult, null]> {
        try {
            const url: string = await LiveSearchApi.rute() + "/interactions/";
            
            const [res, error] = await Base.post(url, {
                drug_ids: drugIds,
                disease_ids: diseaseIds,
                food_ids: foodIds,
            }, InteractionSearchResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene un conteo se interacciones agrupadas por drogas
     * @returns retorna un arreglo con las drogas y el conteo de interacciones
     */
    async drugInteractionCount(): Promise<([DrugInteractionCountResult[], null]) | ([null, Error])> {
        try {
            const url: string = await LiveSearchApi.rute() + "/interactions?groupby=drugs&action=count";
            
            const [res, error] = await Base.getArray(url, DrugInteractionCountResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

}