import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { DrugResult } from "domain/entities//drug/structures/DrugResult";
import { MethodResult } from "domain/entities/method";
import { SeverityResult } from "domain/entities/severity";
import { UserResult } from "domain/entities/user";

@JsonObject("DrugDrugInteractionResult")
export class DrugDrugInteractionResult {
    @JsonProperty("din_id", Number)
    id: number = 0;
    @JsonProperty("dru_id1", Number)
    drugId1: number = 0;
    @JsonProperty("Drug1", DrugResult)
    drug1?: DrugResult;
    @JsonProperty("dru_id2", Number)
    drugId2: number = 0;
    @JsonProperty("Drug2", DrugResult)
    drug2?: DrugResult;
    @JsonProperty("dru_id3", Number)
    drugId3?: Number = 0;
    @JsonProperty("Drug3", DrugResult)
    drug3?: DrugResult;
    @JsonProperty("dse_id", Number)
    severityId: number = 0;
    @JsonProperty("DDISeverity", SeverityResult)
    severity?: SeverityResult;
    @JsonProperty("dty_id", Number)
    methodId: number = 0;
    @JsonProperty("DDIType", MethodResult)
    method?: MethodResult;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("din_relation", String)
    relation: string = "";
    @JsonProperty("din_effect", String)
    effect: string = "";
    @JsonProperty("din_description", String)
    description: string = "";
    @JsonProperty("din_evidence", String)
    evidence: string = "";
    @JsonProperty("din_evidence_source", String)
    evidenceSource: string = "";
    @JsonProperty("din_source", String)
    sources: string = "";
    @JsonProperty("din_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("din_updated_at", DateConverter)
    updatedAt: Date = new Date();
}