import { JsonObject, JsonProperty } from "json2typescript";
import { DrugDiseaseInteractionResult } from "domain/entities/drug-disease-interaction/structures/DrugDiseaseInteractionResult";
import { DrugDrugInteractionResult } from "domain/entities/drug-drug-interaction/structures/DrugDrugInteractionResult";
import { DrugFoodInteractionResult } from "domain/entities/drug-food-interaction/structures/DrugFoodInteractionResult";

@JsonObject("InteractionSearchResult")
export class InteractionSearchResult {
    @JsonProperty("drug_interactions", [DrugDrugInteractionResult])
    drugInteractions?: DrugDrugInteractionResult[];
    @JsonProperty("disease_interactions", [DrugDiseaseInteractionResult])
    diseaseInteractions?: DrugDiseaseInteractionResult[];
    @JsonProperty("food_interactions", [DrugFoodInteractionResult])
    foodInteractions?: DrugFoodInteractionResult[];
}